import { useNavigate } from '@remix-run/react';

import { TrainingProfileEditor } from '../components/TrainingProfile/TrainingProfileEditor';
import { AdminView } from '../pages/Admin/AdminView';
import { AdminToolkitNav } from '../pages/Admin/Toolkit';

export function Component() {
  const navigate = useNavigate();
  return (
    <AdminView className='bg-library-2023-07 p-10 flex flex-col gap-10'>
      <AdminToolkitNav />
      <TrainingProfileEditor
        profile={null}
        onSave={() => navigate('..')}
        onCancel={() => navigate('..')}
      />
      ;
    </AdminView>
  );
}
